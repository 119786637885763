@import "../styles.css";

.sidebar-container {
  width: 250px;
  padding-top: 2.5rem;
  padding-bottom: 2rem;
  background-color: var(--sidebar-background);
  border-radius: 0px 10px 10px 0px;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar-main-text {
  font-size: 16px;
  font-weight: 600;
}

.sidebar-subgroup {
  font-size: 12px;
  font-weight: 600;
  color: var(--sidebar-font-color);
}

span {
  height: 0px;
  white-space: normal;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--sidebar-font-color);
  width: 100px;
}
.no-width {
  width: inherit !important;
}

.active {
  font-size: 16px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--sidebar-active-text);
}

.active-subgroup {
  background: var(--sidebar-active-subtext-background);
  border-radius: 10px;
}

.active-subgroup-text {
  color: var(--sidebar-active-text);
}

.line {
  width: 196px;
  border: 2px solid var(--sidebar-line-color);
}

.sidebar-sign-out {
  color: var(--sidebar-font-color);
  font-size: 18px;
  font-weight: 600;
}

.sidebar-sign-out:hover {
  color: var(--sidebar-active-text);
}

.cursor-pointer {
  cursor: pointer;
}

.support-power-up-green {
  color: var(--non-clickable-button-border-color);
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  position: relative;
}
.support-power-up-green:hover {
  text-decoration: underline;
}
