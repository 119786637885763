@import '../../styles.css';

.threeDots-img {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.threeDots-img-L {
    width: 36px;
    height: 36px;
}

.actions-container {
    background: var(--main-background);
    border-radius: 5px;
    box-shadow: var(--calendareffect-box-shadow, 0px 14px 29.6px 0px rgba(0, 0, 0, 0.05));
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap:5px;
    padding: 8px;
    position: absolute;
    top: 30px;
    min-width: 180px;
}

.actions-container-L {
    position: absolute;
    top: 50px;
}

.action-hoverable {
    background-color: var(--table-row-background-2);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 8px;
    cursor: pointer;
}

.action-hoverable:hover {
    background-color: var(--table-hovered-row);
    cursor: pointer;
}