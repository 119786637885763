@import "../../../styles.css";

.searchInputSessionHistory {
  border-radius: 10px;
  border-style: solid;
  border-color: var(--input-field-bottom-border-color);
  background-color: var(--main-background);
  border-width: 2px;
  height: 40px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: center;
  width: 360px;
  padding: 8px 8px 8px 40px;
}

.charging-sessions-history,
.charging-sessions-history * {
  box-sizing: border-box;
}
.custom-height {
  height: calc(100vh - 6rem);
}

.charging-sessions-history {
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  position: relative;
  padding: 5px;
}

.charging-sessions-history-span {
  color: var(--main-font-color);
  font-size: 24px;
  font-weight: 600;
}

.charging-sessions-history-span2 {
  color: var(--clickable-button-background);
  font-size: 24px;
  font-weight: 600;
}

.calendarButton {
  border-radius: 10px;
  border: 1px solid var(--non-clickable-button-border-color);
  background-color: var(--non-clickable-button-background);
  padding: 8px;
  height: 40px;
  width: 200px;
  color: var(--non-clickable-button-font-color);
  text-align: left;
  font-family: "Inter";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  position: relative;
}

.calendarButtonActive {
  border: 1px solid var(--clickable-button-background);
  color: var(--clickable-button-font-color);
  background-color: var(--clickable-button-background);
  cursor: pointer;
}

.horizontal-gray-line {
  height: 2px;
  background-color: #b5bac680;
}
