@import "../../styles.css";

.table-footer {
  background-color: var(--main-background);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
  width: 100%;
}

.per-page {
  background: var(--footer-paging-light-background);
  border-radius: 6px;
  padding: 3px 5px;
  color: var(--main-font-color);
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  position: relative;
}

.per-page:hover {
  cursor: pointer;
}

.items-per-page {
  color: var(--main-font-color);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  position: relative;
}

.per-page-selected {
  background: var(--clickable-button-background);
  color: var(--clickable-button-font-color);
}

.one-of-five {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  margin-left: 0px;
  margin-right: 0px;
  border-style: solid;
  border-color: var(--footer-paging-border-color);
  border-width: 0.5px 0px 0.5px 0px;
  color: var(--main-font-color);
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}

.arrow-left {
  background: var(--footer-paging-light-background);
  border-radius: 5px 0px 0px 5px;
}

.arrow-right {
  background: var(--footer-paging-light-background);
  border-radius: 0px 5px 5px 0px;
}

.arrow-blue {
  background: var(--clickable-button-background);
  cursor: pointer;
}
