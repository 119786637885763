.leaflet-container {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.leaflet-marker-icon:hover {
  transform: scale(1.2);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.leaflet-top.leaflet-right .leaflet-control-zoom {
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* Green border */
  border-radius: 5px;
  /* Rounded corners */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  /* Shadow for a 3D effect */
}
.leaflet-right .leaflet-control {
  margin-right: 37px;
}
.leaflet-top .leaflet-control {
  margin-top: 43px;
}

.leaflet-touch .leaflet-bar a {
  width: 35px;
  height: 36px;
  line-height: 40px;
}
.leaflet-control-zoom-in span,
.leaflet-control-zoom-out span {
  font-size: 32px;
  color: #111f47;
  font-weight: 200;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  width: 35px;
  height: 36px;
  background: #fff;
  color: red;
  font-size: 14px;
}
.marker-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.circle {
  position: absolute;
  top: 7.5px;
  right: 15.3px;
  width: 42px;
  height: 42px;
  border: 2.5px solid #e7e9ed;
  border-radius: 50%;
}
.newLocation-circle {
  position: absolute;
  top: 7.3px;
  right: 11.7px;
  width: 42px;
  height: 42px;
  border: 2.5px solid #e7e9ed;
  border-radius: 50%;
}

.newLocation {
  border: 2px solid #1e4cdc;
  position: absolute;
  top: 4px;
  right: 9.3px;
  width: 27px;
  height: 27px;
  border-radius: 50%;
}

.number-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 15px;
  color: #e7e9ed;
  background-color: transparent;
  box-shadow: none;
}
.marker {
  position: relative;
}
.newMarker {
  position: relative;
  width: 100%;
  height: 100%;
}
/* .marker:hover .circle,
.marker:hover .number-overlay {
  border-color: #1e4cdc;
  color: #1e4cdc;
} */

.marker:hover {
  transform: scale(1.15);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}
.custom-popup {
  width: 200px;
  border-radius: 10px;
  box-shadow: 0px 14px 29.6px 0px rgba(0, 0, 0, 0.05);
}
.custom-popup .upper-part {
  background: #fff;
  padding: 30px 10px 20px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.custom-popup .confirm-button {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 13px 10px;
  height: 34px;
  background: #1e4cdc;
  color: #fff;
}
.custom-popup .confirm-button:hover {
  background: #3459c9;
}
.custom-popup .text {
  color: #111f47;
  font-size: 10px;
  font-family: "Inter";
  font-weight: 500;
  text-align: center;
}
