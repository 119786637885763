@import "../../styles.css";

.editPricingGroupBtn {
  border-radius: 10px;
  height: 40px;
  width: 140px;
  color: var(--non-clickable-button-font-color);
  background-color: var(--non-clickable-button-background);
  font-size: 14px;
  font-weight: 500;
  cursor: default;
}

.editPricingGroupBtnActive {
  border-color: var(--clickable-button-background);
  background-color: var(--clickable-button-background);
  color: var(--clickable-button-font-color);
  cursor: pointer;
}

.deletePricingGroupBtnActive {
  border-color: #D51C0D;
  background-color: #D51C0D;
  color: var(--clickable-button-font-color);
  cursor: pointer;
}

.createNewBtn {
  border-radius: 10px;
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  gap: 7px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 40px;
  width: 160px;

  border-color: var(--clickable-button-background);
  background-color: var(--clickable-button-background);
  color: var(--clickable-button-font-color);
  cursor: pointer;
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
}

.delete-pricing-group-popup {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: start;
  text-align: left;
  height: 100%;
}

.pricing-groups,
.pricing-groups * {
  box-sizing: border-box;
}

.pricing-groups {
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  position: relative;
  padding: 5px;
}

.pricing-groups-span {
  color: var(--main-font-color);
  font-size: 24px;
  font-weight: 600;
}

.pricing-groups-span2 {
  color: var(--clickable-button-background);
  font-size: 24px;
  font-weight: 600;
}

.popout-main-text {
  color: var(--main-font-color);
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}