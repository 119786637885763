@import '../../styles.css';

.absolute-evse-status {
    z-index: 1001;
    position: fixed;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 50%;
    background-color: var(--main-background);
    overflow: auto;
}

.availableText {
    font-size: 24px;
    font-weight: 600;
    color: var(--main-font-color);
}

.evse-circle {
    width: 22px;
    height: 22px;
    border-radius: 100px;
    background: #FFF;
}

.evse-red-circle {
    border: 4px solid #ED1F0E;
}

.evse-yellow-circle {
    border: 4px solid #FCDC4D;
}

.evse-green-circle {
    border: 4px solid #03CEA4;
}