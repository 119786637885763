.location-modal-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 10px;
  width: 315px;
  white-space: nowrap;
}

.location-modal-btns button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 12px 15px;
  font-size: 12px;
  text-wrap: nowrap;
  height: 38px;
  font-weight: 600;
  white-space: nowrap;
}

.location-modal-btns button.active {
  color: #e7e9ed;
  background: #111f47;
}

.location-modal-btns button.inactive {
  color: #111f47;
  background-color: rgba(231, 233, 237, 0.5);
}
.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  transition: transform 0.3s ease-in-out;
  z-index: -1;
  border-radius: 5px;
}

.btn-group {
  display: flex;
  align-items: center;
  padding: 5px;
  z-index: 1;
  gap: 15px;
  justify-content: center;
  background: #f2f2f2;
  border-radius: 10px;
  width: 315px;
  height: 40px;
  border: 2px solid #f2f2f2;
  position: relative;
}
.btn-group .btn-one {
  transition: 0.5s;
  cursor: pointer;
}
.btn-group .btn-one span {
  color: #a6a6a6;
  font-size: 12px;
  font-weight: 500;
}
.slider {
  background: #111f47;
  border-radius: 10px;
  height: 37px;
  width: 140px;
  margin-left: -0px;
  position: absolute;
  z-index: -1;
  top: 0;
  transition: 0.5s;
}
.btn-group .btn-two {
  cursor: pointer;
  color: #fff;
}
.btn-one:focus + .slider {
  margin-left: 200px;
}
