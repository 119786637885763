@import "../../styles.css";

.advanced-filter-btn {
  border-radius: 10px;
  border-style: solid;
  border-color: var(--non-clickable-button-border-color);
  background-color: var(--non-clickable-button-background);
  border-width: 1px;
  padding: 10px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  color: var(--non-clickable-button-font-color);
  font-family: "Inter";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  cursor: pointer;
  width: 195px;
  height: 40px;
}

.advanced-filter-btn-active {
  border: 1px solid var(--clickable-button-background);
  background-color: var(--clickable-button-background);
  color: var(--clickable-button-font-color);
  cursor: pointer;
}

.advanced-filter-container {
  position: absolute;
  top: 50px;
  width: 240px;
  background: var(--main-background);
  border-radius: 5px;
  box-shadow: var(
    --calendareffect-box-shadow,
    0px 14px 29.6px 0px rgba(0, 0, 0, 0.05)
  );
  display: flex;
  flex-direction: column;
  /* justify-content: start; */
  align-items: center;
  gap: 5px;
  padding: 8px;
  /* padding-bottom: 14px; */
  z-index: 2000;
  max-height: calc(100vh - 250px);
}
.scrollable-container {
  overflow-y: auto;
}

.scrollable-container::-webkit-scrollbar {
  width: 0px;
}

.filter-component-inactive {
  border-bottom: 2px solid #f4f4f4;
}
.filter-component-inactive .component-name {
  color: #b5bac6;
}

.filter-component-active {
  border-bottom: 2px solid #f4f4f4;
}
.filter-component-active .component-name {
  color: var(--main-font-color);
}

.choose-all-clear {
  color: #1e4cdc;
  font-size: 11px;
  font-weight: 400;
  text-decoration-line: underline;
  cursor: pointer;
}

.market-dropdown-hoverable {
  background-color: var(--main-background);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;

  color: var(--main-font-color);
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}

.market-dropdown-hoverable:hover {
  background-color: var(--table-hovered-row);
  cursor: pointer;
}

.market-dropdown-selected {
  background-color: var(--table-hovered-row);
}

.filter-reset {
  border-radius: 10px;
  background: rgba(231, 233, 237, 0.5);
  width: 90px;
  height: 37px;
  font-size: 14px;
  font-weight: 500;
}

.filter-apply {
  border-radius: 10px;
  background: var(--clickable-button-background);
  color: white;
  width: 90px;
  height: 37px;
  font-size: 14px;
  font-weight: 500;
}

.checker-border {
  border-radius: 2px;
  border-style: solid;
  border-color: #404a6b;
  border-width: 1px;
  flex-shrink: 0;
  width: 13px;
  height: 13px;
  position: relative;
  overflow: hidden;
}
