@import '../../styles.css';

.profile-card-container {
    background-color: var(--main-background);
    color: var(--main-font-color);
    border-radius: 10px;
}

.name-surname {
    font-size: 36px;
    font-weight: 600;
}

.super-admin {
    color: #B5BAC6;
    font-size: 22px;
    font-weight: 400;
}

.account-info-text {
    font-size: 18px;
    font-weight: 600;
}

.account-info-input-readonly {
    background-color: #E7E9ED80;
    border-color: #B5BAC680;
    color: var(--main-font-color);
    font-size: 14px;
    font-weight: 500;
    height: 45px;
    border-radius: 10px;
    padding: 0px 10px;
    width: 85%;
    outline: none;
}

.change-password-btn {
    width: 85%;
    height: 50px;
    border-radius: 10px;
    background: var(--clickable-button-background);
    color: var(--clickable-button-font-color);
    font-size: 14px;
    font-weight: 500;
}

.absolute-change-password {
    z-index: 1001;
    position: fixed;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 33%;
    background-color: var(--main-background);
    overflow: auto;
}

.change-password-text {
    font-size: 20px;
    font-weight: 600;
    color: var(--main-font-color);
}

.password-input {
    color: var(--main-font-color);
    font-size: 14px;
    font-weight: 500;
    border: 2px solid #B5BAC680;
    height: 45px;
    border-radius: 10px;
    padding: 0px 10px;
    width: 100%;
    outline: none;
}

.password-input:focus {
    border: 2px solid #111F47;
}

.change-password-header {
    color: var(--main-font-color);
    font-size: 16px;
    font-weight: 600;
}

.three-stripes {
    width: 3.2rem;
    height: 0.25rem;
    border-radius: 20px;
    background-color: var(--footer-paging-border-color);
}

.red {
    background-color: #ED1F0E;
}

.yellow {
    background-color: #F2CE16;
}

.green {
    background-color: #0AB051;
}

.red-font {
    color: #ED1F0E;
    font-size: 14px;
    font-weight: 500;
}

.yellow-font {
    color: #F2CE16;
    font-size: 14px;
    font-weight: 500;
}

.green-font {
    color: #0AB051;
    font-size: 14px;
    font-weight: 500;
}

.change-password-confirm-btn {
    background: var(--non-clickable-button-background);
    color: var(--non-clickable-button-font-color);
    border-radius: 10px;
    padding: 15px;
    font-size: 14px;
    font-weight: 600;
}

.change-password-confirm-btn-active {
    background-color: var(--clickable-button-background);
    color: var(--clickable-button-font-color);
    cursor: pointer;
}

.user-header {
    font-size: 14px;
    font-weight: 700;
}

input[type="password"],
input[type="password"]:focus {
    font-weight: 900;
    letter-spacing: 1.5px;
}

.visible-passwords {
    width: 21px;
    position: absolute;
    right: 15px;
    top: 14px;
    cursor: pointer;
}

.password-requirements {
    color: #B5BAC6;
    text-align: left;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 12px;
    font-weight: 400;
}