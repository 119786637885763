
.calendar-modal-background {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.modal {
    z-index: 1001;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position:fixed;
    padding: 10px 30px;
    position: absolute;
    right: -148px;
    top: 430px;
    width: calc(100vw - 10em);
    overflow: auto;
    width: fit-content;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
 }

.input-between-line{
    position: absolute;
    top: 50px;
    right: 182px;
    border-bottom: 2px solid #111F47;
    width: 10px;
}

.react-calendar span{
    font-size: 18px;
    color: #111F47;
    font-weight: 700;
}
abbr:where([title]) {
    -webkit-text-decoration: none !important;
    text-decoration: none !important;    
}
.react-calendar__month-view__weekdays {
    color: #111F47;
    text-transform: none !important;
    font-family: 'Inter' !important;
    font-size: 14px !important;
    font-weight: 500;
    font-style: none !important;
    text-decoration: none !important;
    border: none !important;
}
.react-calendar__tile--hasActive {
    background: #006edc !important;
    color: white !important;
}
.calendar-tile:active, 
.calendar-tile:focus{
    background: #006edc !important;
    color: white !important;
}
.react-calendar__tile--active.react-calendar__month-view__days__day--weekend,
.react-calendar__tile--hasActive.react-calendar__month-view__days__day--weekend,
.react-calendar__tile--active.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__tile--hasActive.react-calendar__month-view__days__day--neighboringMonth {
    background: #006edc !important;
    color: white !important;
}

.react-calendar__tile abbr{
    font-family: 'Inter';
    font-weight: 500;
    font-size: 12px !important;
}
.react-calendar__navigation button{
    font-size: 18px;
    color: #111F47;
}
.react-calendar__month-view__days__day--neighboringMonth{
    color: #A6A6A6 !important;
}
.calendar{
    border: none !important;
    color: #A6A6A6;

}
.react-calendar__month-view__days__day--weekend {
    color: #A6A6A6 !important;
}

.line-item-divider {
    border-top: 2px solid #F2F2F2;
    width: 100%;
    margin: 5px 0;
}
.calendar-btn-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 6px;
    margin: 5px 0;
}
.calendar-btn-container .col{
    display: flex;
    gap: 10px;
}
.calendar-btn{
    font-size: 12px;
    font-weight: 500;
    color: #111F47;
    padding: 8px 12px;
    border-radius: 10px;
    border: 1px solid #F2F2F2;
}
.calendar-btn:focus{
    color: #FFFFFF;
    background: #1E4CDC;
}
.calendar-btn:hover {
    color: #FFFFFF;
    background: #1E4CDC;
}

.saveButtonRdr {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    width: 117px;
    height: 40px;
    padding: 13px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: var(--blue, #1E4CDC);
}

.resetButtonRdr {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    width: 117px;
    height: 40px;
    padding: 13px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: rgba(231, 233, 237, 0.50);
}
