@import "../../styles.css";

.company-title {
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  position: relative;
  padding: 5px;
}

.custom-scrollbar-company::-webkit-scrollbar {
  width: 3px;
}

.company-title-span {
  color: var(--main-font-color);
  font-size: 24px;
  font-weight: 600;
}

.company-info-container {
  border-bottom: 2.5px solid #b5bac680;
}

.company-info-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--main-font-color);
}

.company-info-subtitle {
  font-size: 14px;
  font-weight: 500;
  color: var(--main-font-color);
}

.company-info-text {
  font-size: 14px;
  font-weight: 500;
  color: var(--table-row-font-color);
}

.absolute-edit-company {
  z-index: 1001;
  position: fixed;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 45%;
  background-color: var(--main-background);
  overflow: auto;
}

.edit-market-text {
  font-size: 20px;
  font-weight: 600;
  color: var(--main-font-color);
}

.edit-market-input {
  color: var(--main-font-color);
  font-size: 14px;
  font-weight: 500;
  border: 2px solid #b5bac6;
  height: 45px;
  border-radius: 10px;
  padding: 0px 10px;
  width: 100%;
  outline: none;
}

input::placeholder {
  color: #b5bac6;
}

.edit-market-input:focus {
  border: 2px solid #111f47;
}

.edit-market-input-readonly {
  background-color: #e7e9ed80;
  border-color: #e7e9ed80;
  color: var(--main-font-color);
  font-size: 14px;
  font-weight: 500;
  height: 45px;
  border-radius: 10px;
  padding: 0px 10px;
  width: 100%;
  outline: none;
}

.edit-market-header {
  color: var(--main-font-color);
  font-size: 16px;
  font-weight: 600;
}

.edit-market-confirm-btn {
  background: var(--non-clickable-button-background);
  color: var(--non-clickable-button-font-color);
  border-radius: 10px;
  width: 123px;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
}

.edit-market-confirm-btn-active {
  background-color: var(--clickable-button-background);
  color: var(--clickable-button-font-color);
  cursor: pointer;
}

.address-options-container {
  position: absolute;
  top: 90px;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  box-shadow: var(
    --calendareffect-box-shadow,
    0px 14px 29.6px 0px rgba(0, 0, 0, 0.05)
  );
}

.edit-company-btn {
  border-radius: 10px;
  background-color: var(--non-clickable-button-background);
  font-size: 14px;
  height: 40px;
  width: 127px;
  color: var(--non-clickable-button-font-color);
  font-weight: 500;
}

.edit-company-btn-active {
  background-color: var(--clickable-button-background);
  color: var(--clickable-button-font-color);
  cursor: pointer;
}

.create-market-btn {
  border-radius: 10px;
  background-color: var(--clickable-button-background);
  color: var(--clickable-button-font-color);
  height: 40px;
  width: 196px;
  font-size: 14px;
  font-weight: 500;
}

.edit-input {
  color: #111f47;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  outline: none;
  padding: 18px 0px !important;
  height: 45px;
  border-bottom: 2px solid var(--footer-paging-border-color);
}

.edit-input:focus {
  border-bottom: 2px solid var(--clickable-button-background);
}

input[type="password"],
input[type="password"]:focus {
  font-weight: 900;
  letter-spacing: 1.5px;
}

.create-market-popup {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: start;
  text-align: left;
  height: 100%;
}
