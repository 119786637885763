.create-location-container {
  z-index: 1200;
  position: fixed;
  display: inline-flex;
  flex-direction: column;
  right: 0px;
  top: 0px;
  height: 100%;
  border-radius: 10px 0px 0px 10px;
  background-color: var(--main-background);
  overflow: auto;
  transition: width 0.2s ease-in-out;
}
.modal-header {
  display: flex;
  padding: 40px 30px;
  width: 100%;
  align-items: center;
}
.create-location-container.wide {
  width: 57%;
}

.create-location-container.narrow {
  width: 45%;
}
.line {
  width: 100%;
}
.content-location-container {
  display: flex;
  width: 100%;
  padding-left: 48px;
  padding-right: 48px;
  flex-direction: column;
  align-items: flex-start;
}
