.profile-modal-container {
    position: absolute;
    top: 60px;
    width: 260px;
    background: var(--main-background);
    border-radius: 10px;
    box-shadow: 0px 7px 9.9px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap:5px;
    padding: 18px;
    z-index: 2000;
}

.username {
    color: var(--main-font-color);
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    position: relative;
    align-self: center;
}

.profile-icon {
    width: 44px;
}

.settings {
    display: flex;
    gap: 8px;
    align-items: center;
    border-radius: 10px;
    color: var(--main-font-color);
    font-size: 14px;
    font-weight: 500;
    padding: 8px;
    width: 100%;
    cursor: pointer;
    border-color: #F4F4F4;
}

.settings:hover {
    background: rgba(231, 233, 237, 0.50);
}
