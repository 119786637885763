.need-help-container {
    position: absolute;
    top: 50px;
    width: 300px;
    background: var(--main-background);
    border-radius: 10px;
    box-shadow: 0px 7px 9.9px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap:15px;
    padding: 18px;
    z-index: 2000;
}

.need-help {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    border-bottom: 2px solid #F4F4F4;
    padding-bottom: 7px;
    width: 100%;
    color: var(--main-font-color);
}

.support-mail {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-decoration-line: underline;
    color: #B5BAC6;
    cursor: pointer;
}