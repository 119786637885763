.operations-modal {
    z-index: 1100;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 500px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: -14px 30px 20px 0px rgba(0, 0, 0, 0.05);
}
.modal-content{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 45px 30px 20px 30px;
    flex-direction: column;
}

.heading{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 12px;
    width: 100%;
}
.heading .title{
    color: #111F47;
    font-size: 18px;
    font-weight: 600;
}
.heading .description {
    color: #B5BAC6;
    font-size: 14px;
    font-weight: 500;
}
.modal-btns {
    display: flex;
    margin-top: auto;
    width: 100%;
}
.modal-btns button{
    display: flex;
    flex: 1;
    color: #111F47;
    font-size: 16px;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    height: 57px;
    padding: 13px 10px;
    background: var(--Gray_04, #E7E9ED);
}
.confirm-selected{
    background: #1E4CDC !important;
    color: #FFFFFF !important;
}
.close-button-border {
    border-right: 1.5px solid #B5BAC6;
}