@import '../../../../styles.css';

.absolute-add-charger {
    z-index: 1001;
    position: fixed;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 40%;
    background-color: var(--main-background);
    overflow: auto;
}

.create-charger-input {
    color: var(--main-font-color);
    font-size: 14px;
    font-weight: 500;
    border: 2px solid #B5BAC6;
    height: 45px;
    border-radius: 10px;
    padding: 0px 10px;
    width: 100%;
    outline: none;
}
.create-charger-input-readonly {
    background-color: #E7E9ED80;
    border-color: #E7E9ED80;
    color: var(--main-font-color);
    font-size: 14px;
    font-weight: 500;
    height: 45px;
    border-radius: 10px;
    padding: 0px 10px;
    width: 100%;
    outline: none;
}

.create-charger-input:focus {
    border: 2px solid #111F47;
}

.connect-btn {
    background: var(--non-clickable-button-background);
    color: var(--non-clickable-button-font-color);
    border-radius: 10px;
    width: 140px;
    height: 40px;
}

.connect-btn-active {
    background-color: var(--clickable-button-background);
    color: var(--clickable-button-font-color);
    cursor: pointer;
}

.addNewText {
    font-size: 24px;
    font-weight: 600;
    color: var(--main-font-color);
}

.input-title {
    font-size: 14px;
    font-weight: 700;
    color: var(--main-font-color);
}
