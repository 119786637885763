@import '../../styles.css';

	.loading-popup-container {
		z-index: 9999;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 60%;
		min-width: 25%;
		border-radius: 10px;
		background-color: var(--main-background);
		box-shadow: 0px 14px 7.8px 0px rgba(0, 0, 0, 0.10);
	}

	.loading-popup-state-title-container {
		padding: 1rem;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		gap: 15px;
		background-color: var(--main-background);
		border-radius: 10px 10px 0px 0px;
        margin-bottom: 5px;
	}

    .state-text {
        color: var(--main-font-color);
        font-size: 26px;
        font-weight: 600;
    }

    .step-text {
        color: var(--main-font-color);
        text-align: center;
        font-size: 12px;
        font-weight: 500;
    }

    .step-status-text {
        padding-top: 4px;
        padding-bottom: 4px;
        min-width: 90px;
        border-radius: 20px;
        border-width: 1px;
        font-size: 10px;
        font-weight: 500;
    }

    .step-completed {
        border-color: #1E4CDC;
        color: #1E4CDC;
        background-color: #E9EDFC;
    }

    .step-in-progress {
        border-color: #111F47;
        color: #111F47;
        background-color: #E7E9ED;
    }

    .step-pending {
        border-color: #E7E9ED;
        color: #E7E9ED;
        background-color: white;
    }

    .step-failed {
        border-color: #BE190B;
        color: #BE190B;
        background-color: #FDE9E7;
    }

    .try-again-btn {
        background-color: var(--clickable-button-background);
        cursor: pointer;
        color: var(--clickable-button-font-color);
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        height: 3.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
    }

    .meter {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%
    }
    .meter span {
        display: block;
        height: 100%;
    }
    .progress {
        height: 100%;
        background-color: #1E4CDC;
        animation: progressBar 1s ease-in-out;
        animation-fill-mode:both; 
    }
    @keyframes progressBar {
        0% { width: 0; }
        100% { width: 100%; }
      }
	
	.bottom-curved {
        border-radius: 0px 0px 10px 10px;
	}