@import '../../../../styles.css';

.modal-background-create-charger {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #030229;
    opacity: 0.3;
}

.create-charger-modal-container {
    z-index: 1001;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    border-radius: 10px;
    background-color: var(--main-background);
}

.popup-text-container-create-charger{
    padding: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 5px;
    background-color: var(--main-background);
    border-radius: 10px 10px 0px 0px;
    margin-bottom: 35px;
}