.location-information-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.flex-location {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-left: 8px;
}
.flex-fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
}
.flex-fields-last {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
  visibility: hidden;
}
.unactive-fields {
  position: relative;
  width: 100%;
  height: 45px;
  background-color: rgba(231, 233, 237, 0.5);
  border-radius: 10px;
}
.unactive-data {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 14px;
  font-weight: 500;
  color: #111f47;
}

.location-btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
}
.flex-location .title {
  color: #111f47;
  font-weight: 600;
}

.location-information-table {
  width: inherit;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
  gap: 20px;
}

.title-1 {
  color: #111f47;
  font-size: 14px;
  font-weight: 700;
}

.flex-location .info-1 {
  color: #a6a6a6;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.editBtnActive {
  display: flex;
  width: 135px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  font-size: 14px;
  border-radius: 10px;
  background: var(--blue, #1e4cdc);
  color: #ffffff;
}
.editBtn {
  display: flex;
  width: 135px;
  height: 40px;
  line-height: 20px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  gap: 15px;
  font-size: 14px;
  border-radius: 10px;
  background-color: rgba(231, 233, 237, 0.5);
  color: #111f47;
}

.location-info-edit-input {
  color: #111f47;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  height: 45px;
  border-radius: 10px;
  padding-left: 10px;
  border: 2.5px solid #b5bac6;
}
.location-info-edit-input-address {
  position: relative;
  color: #a6a6a6;
  font-family: Inter;
  width: 340px;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  padding: 12px 20px;
  height: 45px;
  border-bottom: 2px solid var(--footer-paging-border-color);
}

.absolute-options {
  position: absolute;
  top: 414px;
  right: 38px;
}

.location-info-edit-input:focus {
  border-color: #111f47;
}

.location-line {
  margin-left: 8px;
  width: inherit;
  border: 1px solid #f2f2f2;
}

.charger-information-table {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.delete-location-btn {
  display: flex;
  height: 40px;
  width: 125px;
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 10px;
  color: #fde9e7;
  background: #d51c0d;
}
