@import '../../../../styles.css';

.finish-onboarding,
.finish-onboarding * {
    box-sizing: border-box;
}

.finish-onboarding {
    text-align: left;
    font-size: 24px;
    font-weight: 600;
    position: relative;
}

.finish-onboarding-span {
    color: var(--main-font-color);
    font-size: 24px;
    font-weight: 600;
}

.confirmButton {
    border-radius: 10px;
    border: 1px solid var(--non-clickable-button-border-color);
    background-color: var(--non-clickable-button-background);
    padding: 8px;
    height: 40px;
    width: 220px;
    color: var(--non-clickable-button-font-color);
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    position: relative;
}

.confirmButtonActive {
    border: 1px solid var(--clickable-button-background);
    color: var(--clickable-button-font-color);
    background-color: var(--clickable-button-background);
    cursor: pointer;
}

.connector-header {
    background: #F3F4F6;
    border-radius: 10px;
    padding: 1rem 1.5rem;
    width: 100%;
    color: var(--main-font-color);
    text-align: left;
    font-size: 16px;
    font-weight: 600;
}

.connector-id {
    background: var(--table-row-background-2);
    border-radius: 10px;
    padding: 20px;
    width: 247px;
    height: 44px;
    color: #111E47CC;
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.connector-id-input {
    color: var(--main-font-color);
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    padding: 10px 6px 10px 8px;
    border: 2px solid #B5BAC6;
    border-radius: 10px;
}

.connector-id-input-readonly {
    color: var(--main-font-color);
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    padding: 10px 6px 10px 8px;
    border-radius: 10px;
    background-color: #E7E9ED80;
}

.connector-id-input:focus {
    border: 2px solid #111F47;
}