.revenue-container{
    padding: 24px 40px;
    background-color: var(--main-background);
    color: var(--main-font-color);
    border-radius: 10px;
}

.revenue-cards .title{
    color: #B5BAC6;
    font-size: 18px;
    font-weight: 500;
}
.revenue-cards .price{
    color: #111F47;
    font-size: 36px;
    font-weight: 600;
}
.percent-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 10px;
    border-radius: 10px;
    background-color: #E9EDFC;
}
.percent {
    font-size: 16px;
    color: #1E4CDC;
    font-weight: 600;
}
.percentage .text {
    font-size: 16px;
    color: #111F47;
    font-weight: 500;
}

.user-management-calendarButton {
    position: relative;
    border-radius: 10px;
    border: 1px solid var(--non-clickable-button-border-color);
    background-color: var(--non-clickable-button-background);
    padding: 8px;
    height: 40px;
    width: 200px;
    color: var(--non-clickable-button-font-color);
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    position: relative;
}

.user-management-calendarButtonActivee {
    border: 1px solid var(--clickable-button-background);
    color: var(--clickable-button-font-color);
    background-color: var(--clickable-button-background);
    cursor: pointer;
}