@import "../../styles.css";

th {
  color: var(--table-header-font-color);
  text-align: left;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
  background: var(--table-header-background);
  white-space: nowrap;
  border-right: 2px solid var(--table-border-color);
  border-bottom: 2px solid var(--table-header-border-color);
  white-space: pre-line;
}

td {
  color: var(--table-row-font-color);
  text-align: left;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  border-right: 2px solid var(--table-border-color);
  border-bottom: 2px solid var(--table-row-border-color);
  padding: 12px 10px;
}

#initial-td {
  padding: 0px !important;
  border: 0px !important;
}
.td-selected-row {
  color: #1e3889;
}
table {
  border-collapse: collapse;
  border-spacing: 0px;
  margin: 0px;
  border: 0px;
}
tr:nth-child(2n) {
  background-color: var(--table-row-background-2);
}

.table-container {
  border-radius: 10px;
  background-color: var(--main-background);
  width: 100%;
}

tr {
  background: var(--main-background);
  width: 100%;
}

.selected-row {
  background-color: var(--table-hovered-row) !important;
}

.selectable-row:hover {
  background-color: var(--table-hovered-row);
  cursor: pointer;
}

.checkbox-container {
  background: var(--clickable-button-background);
  border-radius: 5px;
  width: 20px;
  height: 20px;
}

.remove-td {
  width: 55px !important;
}

.selected-row-with-removal {
  background-color: var(--table-hovered-row) !important;
}

.table-header-with-sort {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.blurred-row {
  display: table-row;
  justify-content: space-between;
  align-items: center;

  background: #f0f0f0;
  border-radius: 5px;
  margin-bottom: 10px;
}

.blurred-col {
  flex: 1;
  height: 10px;
  background: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
  margin: 0 5px;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

.table-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.table-scrollbar::-webkit-scrollbar-track {
  background: #e7e9ed80;
}

.table-scrollbar::-webkit-scrollbar-thumb {
  background: #a1a1a1;
  border-radius: 12px;
}
