.modal-table-th{
    color: var(--table-header-font-color);
    font-size: 14px;
    font-weight: 500;
    padding: 15px;
    text-align: left;
    background: #E7E9ED80;
}
.modal-table-tr{
    height: 55px;
    background: white !important;
    border-left: none;
}
.modal-table-td{
    color: var(--table-row-font-color);
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    padding: 7px 12px;
}
.td-selected-row-modal{
    color: #1E3889;
    background: var(--table-hovered-row) ;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    padding: 7px 12px;
}
.modal-table-tr:hover{
    background: var(--table-hovered-row) !important;
    cursor: pointer;
}