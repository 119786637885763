@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Inter", sans-serif !important;
}
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 12px;
}

:root {
  --toastify-toast-offset: 40px !important;
  --toastify-toast-width: auto !important;
  --toastify-max-toast-width: 500px !important; /* Optional: Set a maximum width */
}

.Toastify__toast-container {
  width: var(--toastify-toast-width);
  max-width: var(--toastify-max-toast-width);
}
