@import '../styles.css';

header {
    box-shadow: 0px 4px 4px 0px #0000000a;
}

.header-icons-container {
    background-color: var(--non-clickable-button-background);
    border-radius: 10px;
    width: 43px;
    height: 43px;
    cursor: pointer;
}

.header-vertical-line {
    height: 50px;
    width: 2px;
    border-radius: 10px;
    background-color: rgba(181, 186, 198, 0.50);
}