:root {
    --table-header-font-color: #111F47;
    --table-header-background: #E7E9ED80;
    --table-header-border-color: #B5BAC680;
    --table-row-font-color: #111F4799;
    --table-row-background-2: #FAFBFB;
    --table-hovered-row: #E9EDFC;
    --table-row-border-color: #B5BAC633;

    --three-dots-font-color: #404A6B;

    --subtable-header-background: #E7E9ED80; 
    --subtable-row-background: white;
    --subtable-header-font-color: #111F47;
    --subtable-row-font-color: #111F4799;


    --main-background: white;
    --main-font-color: #111f47;
    --clickable-button-background: #1E4CDC;
    --clickable-button-font-color: #E7E9ED;
    --non-clickable-button-background: #E7E9ED80;
    --non-clickable-button-border-color: #E7E9ED80;
    --non-clickable-button-font-color: #111F47;
    --input-field-bottom-border-color: #B5BAC6;
    --input-field-bottom-border-color-active: #111f47;

    --footer-paging-border-color: #f1f1f1;
    --footer-paging-light-background: #F8F9FA;

    --sidebar-background: #111f47;
    --sidebar-font-color: #7c849a;
    --sidebar-active-text: white;
    --sidebar-active-subtext-background: rgba(255, 255, 255, 0.05);
    --sidebar-line-color: #767D953D;

    --toast-wrong-background: #DB2B39;


}