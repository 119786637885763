.createLocationBtn {
  display: flex;
  justify-content: center;
  background-color: var(--clickable-button-background);
  height: 40px;
  width: 210px;
  color: var(--clickable-button-font-color);
  padding: 10px;
  border-radius: 10px;
  gap: 7px;
  font-size: 14px;
  font-weight: 500;
}
.map-container {
  position: relative;
  height: 100%;
}

.map-container .map-btns {
  position: absolute;
  z-index: 600;
  top: 43px;
  left: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 10px;
  width: 350px;
  height: 60px;
}
.map-btns .button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
}
.button-1 {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.button-2 {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.btns-box_shadow {
  box-shadow: 0px 2px 2.5px 0px rgba(0, 0, 0, 0.1);
}
.map-container .map-btns button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
  height: 40px;
  font-size: 12px;
  width: 100%;
  font-weight: 600;
  transition: transform 0.4s ease, background 0.4s ease, color 0.4s ease;
}

.map-container .map-btns button.active {
  width: 150px;
  color: #e7e9ed;
  line-height: normal;
  background: #111f47;
  transform: translateX(0%);
}

.map-container .map-btns button.inactive {
  width: 150px;
  line-height: normal;
  color: #111f47;
  background-color: rgba(231, 233, 237, 0.5);
  transform: translateX(0%);
}
.btn-disabled {
  opacity: 0.7;
}
