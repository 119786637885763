@import "../../../styles.css";

.create-new-pricing-group-btn {
  border-radius: 10px;
  border-color: var(--non-clickable-button-border-color);
  height: 40px;
  width: 160px;
  color: var(--non-clickable-button-font-color);
  background-color: var(--non-clickable-button-background);
  font-size: 14px;
  font-weight: 500;
  cursor: default;
}

.create-new-pricing-group-btn-active {
  color: var(--clickable-button-font-color);
  border-color: var(--clickable-button-background);
  background-color: var(--clickable-button-background);
  cursor: pointer;
}

.conn-overview-span {
  color: var(--main-font-color);
  text-align: left;
  font-size: 16px;
  font-weight: 600;
}

.connnector-overview-add-btn {
  border-radius: 10px;
  border-color: var(--clickable-button-background);
  background-color: var(--clickable-button-background);
  padding: 10px;
  height: 40px;
  color: var(--clickable-button-font-color);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  width: 180px;
}

.connnector-overview-remove-btn {
  border-radius: 10px;
  border-color: var(--non-clickable-button-background);
  height: 40px;
  color: var(--non-clickable-button-font-color);
  background-color: var(--non-clickable-button-background);
  font-size: 14px;
  font-weight: 500;
  width: 180px;
  cursor: default;
}

.connnector-overview-remove-btn-active {
  color: var(--clickable-button-font-color);
  border-color: var(--clickable-button-background);
  background-color: var(--clickable-button-background);
  cursor: pointer;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.selected-x-connectors {
  color: #111f47;
  font-size: 14px;
  font-weight: 600;
}

.num-of-connectors {
  color: var(--blue, #1e4cdc);
  font-size: 16px;
  font-weight: 600;
}

.eletr-price-input {
  border: none;
  outline: none;
  background-color: var(--main-background);
  width: 80%;
}

.create-edit-header {
  border-radius: 10px;
  background: #FAFBFB;
  height: 35px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  color: rgba(17, 30, 71, 0.80);
  font-size: 12px;
  font-weight: 700;
}

.create-edit-input {
  color: var(--main-font-color);
  font-size: 14px;
  font-weight: 500;
  border: 2px solid #B5BAC6;
  height: 45px;
  border-radius: 10px;
  padding: 0px 10px;
  width: 100%;
  outline: none;
}
.create-edit-input-readonly {
  background-color: #E7E9ED80;
  border-color: #E7E9ED80;
  color: var(--main-font-color);
  font-size: 14px;
  font-weight: 500;
  height: 45px;
  border-radius: 10px;
  padding: 0px 10px;
  width: 100%;
  outline: none;
}

.create-edit-input:focus {
  border: 2px solid #111F47;
}

.add-pricing-component-text {
  color: var(--main-font-color);
  font-size: 14px;
  font-weight: 500;
}

.select-pricing-component-text {
  color: var(--main-font-color);
  font-size: 11px;
  font-weight: 500;
}

.pricing-component-text {
  font-size: 11px;
  font-weight: 500;
}

.pricing-component-text-disabled {
  color: #B5BAC6;
  cursor: default;
}

.pricing-component-text-selected {
  color: #1E4CDC;
  cursor: pointer;
}

.checker-border {
  border-radius: 2px;
  border-style: solid;
  border-color: #404a6b;
  border-width: 1px;
  flex-shrink: 0;
  width: 13px;
  height: 13px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.checker-border-disabled {
  border-radius: 2px;
  border-style: solid;
  border-color: #B5BAC6;
  border-width: 1px;
  flex-shrink: 0;
  width: 13px;
  height: 13px;
  position: relative;
  overflow: hidden;
  cursor: default;
}