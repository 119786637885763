.alarms,
.alarms * {
    box-sizing: border-box;
}

.alarms {
    text-align: left;
    font-size: 24px;
    font-weight: 600;
    position: relative;
    padding: 5px;
}

.alarms-span {
    color: var(--main-font-color);
    font-size: 24px;
    font-weight: 600;
}

.alarms-span2 {
    color: var(--clickable-button-background);
    font-size: 24px;
    font-weight: 600;
}

.alarms-stats-container {
    background-color: var(--main-background);
    border-radius: 10px;
}

.alarms-chart-title {
    font-size: 14px;
    font-weight: 600;
    color: #0B1B4D;
}

.arrived-last-24 {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: var(--main-font-color)
}

.arrived-last-24-num{
    font-size: 64px;
    font-weight: 600;
    color: var(--main-font-color);
}

.warning-row {
    background-color: #FFE8CC;
    color: #FF8800;
}

.error-row {
    background-color: #FFD9DB;
    color: #FF3139;
}

.notification-row {
    background-color: #E9EDFC;
    color: #1E4CDC;
}

.blue-dot-absolute {
    position: absolute;
    left: -15px;
    top: 20px
}

.unread-row {
    background-color: #E7E9EDCC !important;
}

.unread-td {
    color: #111F4799;
    font-weight: 700;
}

.expanded-text {
    font-size: 12px !important;
}

.mark-all-btn {
    border-color: var(--non-clickable-button-background);
    color: var(--non-clickable-button-font-color);
    background-color: var(--non-clickable-button-background);
    width: 186px;
    height: 40px;
    cursor: default;
    border-radius: 10px;
}

.mark-all-btn-active {
    border-color: var(--clickable-button-background);
    color: var(--clickable-button-font-color);
    background-color: var(--clickable-button-background);
    width: 186px;
    height: 40px;
    cursor: pointer;
    border-radius: 10px;
}