@import '../../../../styles.css';

.instructions-popover-icon-circle {
    border-radius: 100px;
    border-style: solid;
    border-color: var(--table-row-font-color);
    border-width: 1.5px;

    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
}

.instructions-popover-icon-i {
    color: var(--table-row-font-color);
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.instructions-popover-icon-circle:hover {
    border-color: var(--clickable-button-background);
    .instructions-popover-icon-i {
        color: var(--clickable-button-background);
    }
}

.instructions-popover-container {
    box-shadow: var(--calendareffect-box-shadow, 0px 14px 29.6px 0px rgba(0, 0, 0, 0.05));
    background-color: var(--main-background);
    position: relative;
    right: 320px;
    bottom: 150px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: 1rem;
    border-radius: 5px;
    width: 300px;
    z-index: 1001;
}

.instructions-popover-main-text {
    color: var(--main-font-color);
    text-align: left;
    font-size: 14px;
    line-height: 130%;
    font-weight: 600;
    padding-bottom: 25px;
}

.instructions-popover-row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0rem;
    border-bottom: 1px solid #f2f2f2;
    width: 100%;
}

.instructions-popover-row-left {
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    color: var(--main-font-color);
}

.instructions-popover-row-right {
    color: var(--table-row-font-color); 
    text-align: left;
    font-size: 12px;
    font-weight: 400;
}