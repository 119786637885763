@import '../../styles.css';

.toast-container {
    position: fixed;
    top: 0px;
    left: 32.5%;
    width: 35%;
    background-color: var(--clickable-button-background);
    color: var(--clickable-button-font-color);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.toast-update-container {
    position: fixed;
    top: 0px;
    left: 55%;
    width: 35%;
    background-color: var(--clickable-button-background);
    color: var(--clickable-button-font-color);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.toast-container-red {
    background-color: var(--toast-wrong-background);
}

.group-543 {
    flex-shrink: 0;
    width: 23.69px;
    height: 23.69px;
    position: relative;
    overflow: visible;
}