.load-management,
.load-management * {
    box-sizing: border-box;
}

.load-management {
    text-align: left;
    font-size: 24px;
    font-weight: 600;
    position: relative;
    padding: 5px;
}

.load-management-span {
    color: var(--main-font-color);
    font-size: 24px;
    font-weight: 600;
}

.load-management-span2 {
    color: var(--clickable-button-background);
    font-size: 24px;
    font-weight: 600;
}

.absolute-set-power-limit {
    z-index: 1001;
    position: fixed;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 40%;
    background-color: var(--main-background);
    overflow: auto;
}

#table-red-border {
    border-left: 4px solid #ED1F0E;
}

#table-blue-border {
    border-left: 4px solid #1E4CDC;
}

#table-red-bg {
    background-color: #B5BAC633;
}

.checker-border-large {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #000;
  }

.MuiSlider-colorInfo .MuiSlider-track {
    background-color: #1E4CDC;
    height: 10px;
}

.MuiSlider-colorInfo .MuiSlider-rail {
    background-color: #B5BAC6;
    height: 10px;
}

.MuiSlider-colorInfo .MuiSlider-thumb {
    background-color: #FFF;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}