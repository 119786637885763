@import "../../styles.css";

.dashboard,
.dashboard * {
  box-sizing: border-box;
}

.dashboard {
  text-align: left;
  font-family: "Inter";
  font-size: 24px;
  font-weight: 600;
  position: relative;
  padding: 5px;
}

.card {
  box-shadow: 0px 11px 19px 0px #00000008;
  background: var(--main-background);
  border-radius: 10px;
}

.main-wrapper {
  background-color: #f5f5f590;
}

.dashboard-span {
  color: var(--main-font-color);
  font-family: "Inter";
  font-size: 24px;
  font-weight: 600;
}

.dashboard-span2 {
  color: var(--clickable-button-background);
  font-family: "Inter";
  font-size: 24px;
  font-weight: 600;
}

.calendarButton {
  position: relative;
  border-radius: 10px;
  border: 1px solid var(--non-clickable-button-border-color);
  background-color: var(--non-clickable-button-background);
  padding: 8px;
  height: 40px;
  width: 200px;
  color: var(--non-clickable-button-font-color);
  text-align: left;
  font-family: "Inter";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  position: relative;
}

.calendarButtonActivee {
  border: 1px solid var(--clickable-button-background);
  color: var(--clickable-button-font-color);
  background-color: var(--clickable-button-background);
  cursor: pointer;
}

.chart-title {
  color: var(--black-100percent, #1c1c1c);
  text-align: left;
  font-family: "Inter";
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}

.chart-legend {
  color: #1c1c1c;
  text-align: left;
  font-family: "Inter";
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

.chart-container {
  box-shadow: 0px 11px 19px 0px #00000008;
  background: var(--main-background);
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  height: 300px;
  padding-bottom: 4rem;
  gap: 1.5rem;
}

.blue-dot {
  border-radius: 100%;
  background-color: #b9c8f4;
  width: 7px;
  height: 7px;
}

.black-dot {
  border-radius: 100%;
  background-color: #1c1c1c;
  width: 7px;
  height: 7px;
}

.threedots-text {
  color: var(--three-dots-font-color);
  font-family: "Inter";
  font-size: 11px;
  font-weight: 500;
}

.picked-date-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.picked-date-container .date {
  color: var(--non-clickable-button-font-color);
  font-size: 12px;
  margin-right: 24px;
  font-weight: 600;
}
.picked-date-container-blue {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picked-date-container-blue .date {
  color: var(--clickable-button-font-color);
  font-size: 12px;
  font-weight: 600;
}

.date-exit-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 9px;
  top: 10px;
  width: 18px;
  height: 18px;
  z-index: 1000;
}
.date-exit-container:hover {
  background: #f2f2f2;
}
