.notification-container {
  position: absolute;
  top: 50px;
  width: 300px;
  background: var(--main-background);
  border-radius: 10px;
  box-shadow: 0px 7px 9.9px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  z-index: 2000;
}

.notification-bubble {
  text-align: center;
  position: absolute;
  top: 4px;
  right: 4px;
  min-width: 15px;
  min-height: 15px;
  padding: 1px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #ed1f0e;
  color: #ffffff;
  font-size: 11px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bubble {
  min-width: 16px;
  min-height: 16px;
  aspect-ratio: 1;
  text-align: center;
  border-radius: 100px;
  border: 1px solid #b5bac6;
  color: #b5bac6;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bubble-selected {
  min-width: 16px;
  min-height: 16px;
  aspect-ratio: 1;
  text-align: center;
  border-radius: 100px;
  border: 1px solid #111f47;
  color: white;
  background-color: #111f47;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notification {
  border-top: 1.5px solid #b5bac680;
  cursor: pointer;
}

.notification:hover {
  background-color: #dcdfe580;
}

.notification-unread {
  background-color: #e7e9ed80;
}

.notification-slider {
  font-size: 12px;
  color: #b5bac6;
  cursor: pointer;
  margin-bottom: -1.5px;
}

.notification-selected-slider {
  font-size: 12px;
  color: var(--main-font-color);
  border-bottom: 2px solid var(--main-font-color);
  cursor: pointer;
  margin-bottom: -1.5px;
  z-index: 1000;
}

.notification-title {
  font-size: 12px;
  font-weight: 500;
}

.notification-desc {
  font-size: 10px;
  font-weight: 400;
}

.notification-time {
  color: #b5bac6;
  font-size: 10px;
  font-weight: 500;
}

.view-all-container {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 10px;
  background-color: #f3f4f6;
  color: #1e4cdc;
  border-radius: 0px 0px 10px 10px;
}
