@import "../../../styles.css";

.custom-scrollbar-roaming::-webkit-scrollbar {
  width: 5px;
}

.custom-scrollbar-roaming::-webkit-scrollbar-thumb {
  background-color: #b5bac6;
  border-radius: 10px;
}

.custom-scrollbar-roaming::-webkit-scrollbar-track {
  background-color: #f4f4f4;
  border-radius: 10px;
}

.absolute-register-roaming {
  z-index: 1001;
  position: fixed;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 45%;
  background-color: var(--main-background);
  overflow: auto;
}

.roaming-inputs-header {
  color: var(--main-font-color);
  font-size: 14px;
  font-weight: 600;
}

.partners-roaming-confirm-btn {
  width: 390px;
  height: 55px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  color: var(--non-clickable-button-font-color);
  background-color: var(--non-clickable-button-background);
}

.partners-roaming-confirm-btn-active {
  color: var(--clickable-button-font-color);
  background-color: var(--clickable-button-background);
}
