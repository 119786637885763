@import "../../styles.css";

.market-selection-btn {
  border-radius: 10px;
  border-style: solid;
  border-color: var(--non-clickable-button-border-color);
  background-color: var(--non-clickable-button-background);
  border-width: 1px;
  padding: 10px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  color: var(--non-clickable-button-font-color);
  font-family: "Inter";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  cursor: pointer;
  width: auto;
  height: 40px;
}

.market-selection-btn-active {
  border: 1px solid var(--clickable-button-background);
  background-color: var(--clickable-button-background);
  color: var(--clickable-button-font-color);
  cursor: pointer;
}

.market-dropdown-container {
  position: absolute;
  top: 50px;
  height: 450px;
  width: 230px;
  background: var(--main-background);
  border-radius: 5px;
  box-shadow: var(
    --calendareffect-box-shadow,
    0px 14px 29.6px 0px rgba(0, 0, 0, 0.05),
    0px 30px 50px 10px rgba(0, 0, 0, 0.06)
  );

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 5px;
  padding: 8px;
  z-index: 20003;
  margin-bottom: 10px;
}
.market-dropdown-container::-webkit-scrollbar {
  width: 4px;
  border-radius: 12px;
}

.market-dropdown-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.22);
  border-radius: 4px;
}

.market-dropdown-container::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.market-dropdown-hoverable {
  background-color: var(--table-row-background-2);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  color: var(--three-dots-font-color);
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}

.market-dropdown-hoverable:hover {
  background-color: var(--table-hovered-row);
  cursor: pointer;
}

.market-dropdown-selected {
  background-color: var(--table-hovered-row);
}

.checker-border {
  border-radius: 2px;
  border-style: solid;
  border-color: #404a6b;
  border-width: 1px;
  flex-shrink: 0;
  width: 13px;
  height: 13px;
  position: relative;
  overflow: hidden;
}
