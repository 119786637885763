.spinner-container {
    z-index: 1200;
    position: fixed;
    left: 37.5%;
    top: 30%;
    width: 25%;
    height: 30%;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}