@import "../../styles.css";

.modal-background {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(3, 2, 41, 0.2);
  opacity: 0.4;
  backdrop-filter: blur(1.5px);
}

.popup-container {
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 400px;
  min-width: 350px;
  border-radius: 10px;
  background-color: var(--main-background);
  box-shadow: 0px 14px 7.8px 0px rgba(0, 0, 0, 0.1);
}

.popup-text-container {
  padding: 28px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 5px;
  background-color: var(--main-background);
  border-radius: 10px 10px 0px 0px;
  margin-bottom: 35px;
}
.popup-line {
  width: 100%;
  border-bottom: 2px solid #e7e9ed;
  height: 2px;
}
.confirm {
  padding: 1rem;
  background-color: var(--clickable-button-background);
  cursor: pointer;
  color: var(--clickable-button-font-color);
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.cancel {
  padding: 1rem;
  background-color: #e7e9ed;
  cursor: pointer;
  color: var(--main-font-color);
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.left-curved {
  border-radius: 0px 0px 0px 10px;
}

.right-curved {
  border-radius: 0px 0px 10px 0px;
}

.bottom-curved {
  border-radius: 0px 0px 10px 10px;
}
