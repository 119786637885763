.tickets,
.tickets * {
    box-sizing: border-box;
}

.tickets {
    text-align: left;
    font-size: 24px;
    font-weight: 600;
    position: relative;
    padding: 5px;
}

.tickets-span {
    color: var(--main-font-color);
    font-size: 24px;
    font-weight: 600;
}

.tickets-span2 {
    color: var(--clickable-button-background);
    font-size: 24px;
    font-weight: 600;
}

.searchInputTickets {
    border-radius: 10px;
    border-style: solid;
    border-color: var(--input-field-bottom-border-color);
    background-color: var(--main-background);
    border-width: 2px;
    height: 40px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: center;
    width: 340px;
    padding: 8px 8px 8px 40px;
  }

  .tickets-stats-container {
    padding: 24px 40px;
    background-color: var(--main-background);
    border-radius: 10px;
  }

  .tickets-stats-title {
    color: #B5BAC6;
    font-size: 18px;
    font-weight: 500;
  }

  .tickets-stats-num {
    color: var(--main-font-color);
    font-size: 48px;
    font-weight: 600;
  }

  .resolved-row {
    background-color: #E6F5EC;
    color: #0AB051;
  }

  .unresolved-row {
    background-color: #DDE4FA;
    color: #1E4CDC;
  }



  .absolute-ticket-info {
    z-index: 1001;
    position: fixed;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 35%;
    background-color: var(--main-background);
    overflow: auto;
}

.ticket-input {
    color: var(--main-font-color);
    font-size: 14px;
    font-weight: 500;
    border: 2px solid #B5BAC6;
    height: 45px;
    border-radius: 10px;
    padding: 0px 10px;
    width: 100%;
    outline: none;
}
.ticket-input-readonly {
    background-color: #E7E9ED80;
    border-color: #E7E9ED80;
    color: var(--main-font-color);
    font-size: 14px;
    font-weight: 500;
    height: 45px;
    border-radius: 10px;
    padding: 0px 10px;
    width: 100%;
    outline: none;
}

.ticket-textarea {
    color: var(--main-font-color);
    font-size: 14px;
    font-weight: 500;
    border: 2px solid #B5BAC6;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    outline: none;
}

.ticket-textarea-readonly {
    background-color: #E7E9ED80;
    border-color: #E7E9ED80;
    color: var(--main-font-color);
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    outline: none;
}

.respond-btn {
    width: 123px;
    height: 40px;
    border-radius: 10px;
    border-color: var(--non-clickable-button-background);
    background-color: var(--non-clickable-button-background);
    color: var(--non-clickable-button-font-color);
    cursor: default;
}

.respond-btn-active {
    width: 123px;
    height: 40px;
    border-radius: 10px;
    border-color: var(--clickable-button-background);
    background-color: var(--clickable-button-background);
    color: var(--clickable-button-font-color);
    cursor: pointer;
}