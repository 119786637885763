.welcome-name-surname {
    color: var(--main-font-color);
    text-align: left;
    font-size: 32px;
    font-weight: 700;
}

.type-new-password {
    color: var(--table-row-font-color);
    text-align: center;
    font-size: 16px;
    line-height: 130.7%;
    font-weight: 400;
    margin-bottom: 1rem;
}

.password-reset-password {
    color: var(--main-font-color);
    text-align: left;
    font-size: 20px;
    font-weight: 400;
}

.password-reset-btn {
    background: var(--table-hovered-row);
    color: var(--main-font-color);
    border: 1px solid var(--table-hovered-row);
    border-radius: 10px;
    padding: 15px 140px 15px 140px;
    width: 394px;
    height: 56px;
    position: relative;
    font-size: 18px;
    font-weight: 600;
    margin-top: 1rem;
    cursor: default;
}

.password-reset-btn-active {
    background-color: var(--clickable-button-background);
    color: var(--clickable-button-font-color);
    cursor: pointer;
}

.new-password-password-input {
    color: var(--table-row-font-color);
    text-align: left;
    font-size: 20px;
    font-weight: 400;
    outline: none;
    padding: 10px 6px 10px 8px;
    border-bottom: 2px solid var(--footer-paging-border-color);
}

.new-password-password-input:focus {
    border-bottom: 2px solid var(--non-clickable-button-font-color);
    color: var(--main-font-color);
    font-size: 20px;
    font-weight: 400;
    border-bottom: 2px solid var(--clickable-button-background);
}

.newpassword-login-visible-passwords {
    width: 20px;
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
}

.password-requirements {
    color: #B5BAC6;
    text-align: left;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 12px;
    font-weight: 400;
}