@import '../../../styles.css';

.absolute-add-connectors {
    z-index: 1001;
    position: fixed;
    right: 0px;
    top: 0px;
    height: 100%;
    max-width: 70%;
    min-width: 50%;
    background-color: var(--main-background);
    overflow: auto;
}

.addConnectorsText {
    font-size: 22px;
    font-weight: 600;
    color: var(--main-font-color)
}

.confirm-connectors-btn {
    background: var(--non-clickable-button-background);
    color: var(--non-clickable-button-font-color);
    border-radius: 10px;
    width: 140px;
    height: 40px;
}

.confirm-connectors-btn-active {
    background-color: var(--clickable-button-background);
    color: var(--clickable-button-font-color);
    cursor: pointer;
}

.confirm-addition-span {
    color: var(--main-font-color);
    font-size: 16px;
    font-weight: 600;
}
