@import "../../../styles.css";

.historyOutlineButton {
    width: 157px;
    height: 33px;
    border-radius: 5px;
    border: 1px solid var(--non-clickable-button-border-color);
    color: var(--non-clickable-button-font-color);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.historyOutlineButtonActive {
    border: 1px solid var(--clickable-button-background);
    color: var(--clickable-button-background);
}


.searchInputBilling {
    border-radius: 10px;
    border-style: solid;
    border-color: var(--non-clickable-button-border-color);
    background-color: var(--non-clickable-button-background);
    border-width: 1px;
    height: 40px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: center;
    width: 380px;
    padding: 8px 8px 8px 34px;

}

.charging-sessions-history,
.charging-sessions-history * {
    box-sizing: border-box;
}

.charging-sessions-history {
    text-align: left;
    font-size: 24px;
    font-weight: 600;
    position: relative;
    padding: 5px;
}

.charging-sessions-history-span {
    color: var(--main-font-color);
    font-size: 24px;
    font-weight: 600;
}

.charging-sessions-history-span2 {
    color: var(--clickable-button-background);
    font-size: 24px;
    font-weight: 600;
}

.invoiceButton {
    border-radius: 10px;
    border-style: solid;
    border-color: var(--non-clickable-button-border-color);
    background-color: var(--non-clickable-button-background);
    border-width: 1px;
    padding: 8px 8px 8px 8px;
    height: 40px;
    width: 190px;
    color: var(--non-clickable-button-font-color);
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    position: relative;
}

.invoiceButtonActive {
    border: 1px solid var(--clickable-button-background);
    background-color: var(--clickable-button-background);
    color: var(--clickable-button-font-color);
    cursor: pointer;
}